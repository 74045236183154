<ion-app>
    <ion-menu [contentId]="'main-content'">
        <ion-header class="ion-safe-area-top">
            <ion-toolbar [color]="'primary'">
                <ion-title class="bar-title">選單</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content class="ion-padding ion-safe-area-bottom">
            <div *ngFor="let p of this.globalVars.pages | filterBy: ['parent_id']: -1; index as i;">
                <!-- Standard Menu Item -->
                <ion-menu-toggle *ngIf="p.url && p.show">
                    <ion-item class="menu-item" button (click)="openPage(p.id)" [class.parent-active]="p.open" [detail]="false">
                        <ion-label>{{ p['menu_title'] }}</ion-label>
                    </ion-item>
                </ion-menu-toggle>

                <!-- Item with Children -->
                <ion-item class="menu-item" *ngIf="p.children?.length > 0 && p.show" (click)="openPage(p.id)" [class.parent-active]="p.open" [detail]="false">
                    <ion-label>{{ p['menu_title'] }}</ion-label>
                </ion-item>

                <!-- Children List for clicked Item -->
                <ion-list class="page-list" lines="none" [hidden]="!p.open || !p.show">
                    <ion-menu-toggle *ngFor="let sub of p.children">
                        <ion-item *ngIf="sub.show" class="sub-item"
                                  [class.active-item]="sub.id === globalVars.currentPageID"
                                  (click)="globalVars.goToPageByID(sub.id)" detail="false">
                            <ion-img slot="start" class="menu-icon" [hidden]="!sub.icon" [src]="sub.icon"></ion-img>
                            <ion-label>{{ sub['menu_title'] }}</ion-label>
                        </ion-item>
                    </ion-menu-toggle>
                </ion-list>
            </div>
        </ion-content>
    </ion-menu>

    <ion-router-outlet [swipeGesture]="false" id="main-content"></ion-router-outlet>
</ion-app>
