import {Injectable} from '@angular/core';
// import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Firestore, collection, collectionData, doc, docData, addDoc, deleteDoc, updateDoc, snapToData } from '@angular/fire/firestore';

import {HttpClient} from '@angular/common/http';
// import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import { CapacitorHttp } from '@capacitor/core';
import {Platform} from '@ionic/angular';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import * as Constants from '../configs/constants';
import {OAuthConfig} from '../../environments/oauthConfig';
import {DataService} from '../core/services/dataService';
import { setDoc } from 'firebase/firestore';
// import { addAbortSignal } from 'stream';

@Injectable({
    providedIn: 'root'
})
export class MemberService {

    authenticationStatus : BehaviorSubject<boolean>;
    authentication$      : Observable<any>;

    constructor(
        private platform      : Platform,
//         private http          : HTTP,
        private httpClient    : HttpClient,
        // private fireStore     : AngularFirestore,
        private fireStore     : Firestore,
        public dataService    : DataService,
    ) {
        this.platform.ready()
            .then(() => {
                this.checkAccessToken();
            });

        this.authenticationStatus = new BehaviorSubject(false);
        this.authentication$      = this.authenticationStatus.asObservable();
//        this.http.setRequestTimeout(Constants.TIMEOUT_API_LONG);
    }

    /* ==================================================================================================== */

    /* AUTH */

    checkAccessToken() {
        this.dataService.getData(Constants.KEY_CURRENT_USER)
            .then(value => {
                if (value) {
                    this.authenticationStatus.next(value.access_token.length > 0);
                }
            });
    }

    toggleAuthStatus(status: boolean) {
        this.authenticationStatus.next(status);
    }

    checkAuthStatus() {
        return this.authenticationStatus.value;
    }

    checkSingleLogin(args: any): Observable<any> {
        if (!args.username)
            return null;
        else {
            let docRef = doc(this.fireStore, Constants.COLLECTION_USER_PROFILES + "/" + args.username);
            return docData(docRef);
        }
            // return this.fireStore
            //     .collection<any>(Constants.COLLECTION_USER_PROFILES)
            //     .doc(args.username)
            //     .snapshotChanges()
            //     .pipe(
            //         map(values => values.payload.data())
            //     );
    }

    refreshToken(token: string) {
        let url = `${Constants.API_OAUTH}/token`;
        let body = {
            grant_type    : 'refresh_token',
            refresh_token : token,
            client_id     : OAuthConfig.oauthConfig.clientId,
            client_secret : OAuthConfig.oauthConfig.clientSecret,
        };
        let headers = {
            "Content-Type" : 'application/x-www-form-urlencoded',
            // "Authorization": "Basic djFrQlk1Y2hvNm13cWlVWDB2QWVjVjNsbUJydGlFQk9HVnBFMUNXcDpJaVc5QVE3QW1IUHpEU1ZadG10TTE0QUgwWm0xQ2lya0JudWJlQk1B",
            // "Content-Type" : 'application/json'
        };
        const httpResponse = CapacitorHttp.post({url: url, method:'post', data:body, headers:headers});
        return httpResponse;

        // return this.http.post(url, params, {});
    }

    login(user: any) {
        let url = `${Constants.API_OAUTH}/token`;
        let body = {
            "grant_type"    : 'password',
            "username"      : user.username,
            "password"      : user.password,
            "client_id"     : OAuthConfig.oauthConfig.clientId,
            "client_secret" : OAuthConfig.oauthConfig.clientSecret,
        };
        let headers = {
           "Content-Type" : 'application/x-www-form-urlencoded',
            // "Authorization": "Basic djFrQlk1Y2hvNm13cWlVWDB2QWVjVjNsbUJydGlFQk9HVnBFMUNXcDpJaVc5QVE3QW1IUHpEU1ZadG10TTE0QUgwWm0xQ2lya0JudWJlQk1B",
            // "Content-Type" : 'application/json'
        };
        const httpResponse = CapacitorHttp.post({url: url, method:'post', data:body, headers:headers});
        console.log(httpResponse);

        return httpResponse;
        // return this.http.post(url, params, {});
    }

    checkUser(token: string) {
        let url = Constants.API_USER + '/me';
        let body = {
            access_token: token,
        };
        let headers = {
            "Content-Type" : 'application/x-www-form-urlencoded',
        //    "Content-Type" : 'application/json'
        };
        const httpResponse = CapacitorHttp.post({url: url, method:'post', data:body, headers:headers});
        return httpResponse;
        // return this.http.post(url, params, {});
    }

    fetchUserProfile(args: any) {
        let url = Constants.API_MEMCARD + '/fetchUser.php';
        let body = {
            username: args.username,
        };
        let headers = {
            "Content-Type" : 'application/x-www-form-urlencoded',
        };
        const httpResponse = CapacitorHttp.post({url: url, method:'post', data:body, headers:headers});
        return httpResponse;
        // return this.http.post(url, params, {});
    }

    async updateUserProfile(args: any, escape: boolean) {
        // 0. variables
        let status = {code: -1, msg: ''};
        let params = {
            id          : args.id,
            email       : args.email,
            phone       : args.phone,
            unit        : args.unit,
        };
        // 1. WordPress
        if (args.identity == Constants.IDENTITY.MEMBER) {
            if (escape) {
                status = {code: 200, msg: ''};
            } else {
                await this.updateMember(params)
                    .then(result => {
                        status = {code: result.status, msg: JSON.parse(result.data).message};
                        return status;
                    })
                    .catch(reason => {
                        status = {code: reason.status, msg: JSON.parse(reason.error).message};
                        return status;
                    });
            }
        }

        if (args.identity == Constants.IDENTITY.GUEST || status.code === 200) {
            // 2. Firestore
            await setDoc(doc(this.fireStore, Constants.COLLECTION_USER_PROFILES + "/" + args.username), args);
            // await this.fireStore
            //     .collection(Constants.COLLECTION_USER_PROFILES)
            //     .doc(args.username)
            //     .set(args)
            //     .then(() => {});
            if (args.identity == Constants.IDENTITY.GUEST) {
                status = {code: 200, msg: '你的個人資料已更新。\n\n一旦登出，你的個人資料將被清除！'};
            }
            return status;
        }

        return status;
    }


    getAccessToken(user: any) {
        let url = `${Constants.API_OAUTH}/token`;
        let body = {
            grant_type    : 'password',
            username      : user.username,
            password      : user.password,
            client_id     : OAuthConfig.oauthConfig.clientId,
            client_secret : OAuthConfig.oauthConfig.clientSecret,
        };
        let headers = {
           "Content-Type" : 'application/x-www-form-urlencoded',
            // "Authorization": "Basic djFrQlk1Y2hvNm13cWlVWDB2QWVjVjNsbUJydGlFQk9HVnBFMUNXcDpJaVc5QVE3QW1IUHpEU1ZadG10TTE0QUgwWm0xQ2lya0JudWJlQk1B",
            // "Content-Type" : 'application/json'
        };
        const httpResponse = CapacitorHttp.post({url: url, method:'post', data:body, headers:headers});
        return httpResponse;

        // return this.http.post(url, params, {});
    }

    fetchUserSnapshot(params: any) {
        this.getAccessToken(params)
            .then()
    }

    /* ==================================================================================================== */

    /* WordPress */

    // readMember(args: any) {
    //     let url = Constants.API_MEMBER + '/read/' + args.id;
    //     return this.http.post(url, {}, {});
    // }

    updateMember(args: any) {
        let url = Constants.API_MEMBER + '/update/' + args.id;
        return CapacitorHttp.post({url: url});
        // return this.http.post(url, args, {});
    }

    /* ==================================================================================================== */

    /* MemCard */

    redeemTicket(args: any) {
        let url = Constants.API_MEMCARD + '/redeemTicket.php';
        let body = {
            username   : args.username,
            cardnumber : args.cardnumber,
        };
        let headers = {
            "Content-Type" : 'application/x-www-form-urlencoded',
        };
        const httpResponse = CapacitorHttp.post({url: url, method:'post', data:body, headers:headers});
        return httpResponse;

        // return this.http.post(url, params, {});
    }

    fetchBalance(args: any) {
        let url = Constants.API_MEMCARD + '/fetchBalance.php';
        let body = {
            username   : args.username,
        };
        let headers = {
            "Content-Type" : 'application/x-www-form-urlencoded',
        };
        const httpResponse = CapacitorHttp.post({url: url, method:'post', data:body, headers:headers});
        return httpResponse;

        // return this.http.post(url, params, {});
    }

    /* ==================================================================================================== */

    /* Qualifications */

    getQualifications(args: any) : Observable<any> {
        return docData(doc(this.fireStore, Constants.COLLECTION_USER_PROFILES + "/" + args.username));
        // return this.fireStore
        //     .collection<any>(Constants.COLLECTION_USER_PROFILES)
        //     .doc(args.username)
        //     .snapshotChanges().pipe(
        //         map(values => {
        //             return values.payload.data();
        //         })
        //     );
    }

}
