import { environment } from 'src/environments/environment.prod';
import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';
import {Router} from '@angular/router';
import {interval, Observable, of, Subscription, zip} from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    finalize,
    map,
    startWith,
    take
} from 'rxjs/operators';
import * as Constants from '../configs/constants';
import * as moment from 'moment';
export default moment;
/* ==================================================================================================== */
/* Plugins */
import {Device} from '@awesome-cordova-plugins/device/ngx';
import {Market} from '@awesome-cordova-plugins/market/ngx';
import {SocialSharing} from '@awesome-cordova-plugins/social-sharing/ngx';
import {InAppBrowser} from '@awesome-cordova-plugins/in-app-browser/ngx';
/* ==================================================================================================== */
/* Services */
import {ApiService} from './apiService';
import {LogService} from './logService';
import {DateService} from '../core/services/dateService';
import {DataService} from '../core/services/dataService';
import {MemberService} from './memberService';
import {NetworkService} from './networkService';
import {MessageService} from './messageService';
import {ModalService} from './modalService';
/* ==================================================================================================== */
/* Models */
import {User} from '../models/user.model';
import {Log} from '../models/log.model';
import {Page} from '../models/page.model';
import {Alert} from '../models/alert.model';
import {Notification} from '../models/notification.model';
import {Banner} from '../models/banner.model';
import {Poster} from '../models/poster.model';
import {Grid} from '../models/grid.model';
import {Verse} from '../models/verse.model';
import {HymnCategory} from '../models/hymn-category.model';
import {HymnPost} from '../models/hymn-post.model';
import {Devotion} from '../models/devotion.model';
import {DevotionCollection} from '../models/devotion-collection.model';
import {ShareItem} from '../models/share-item.model';
import {LentCard} from '../models/lent-card.model';
/* ==================================================================================================== */
/* Festival Related */
import {LentDate} from '../models/lent-date.model';
import {LentEntry} from '../models/lent-entry.model';
import {Prayer} from '../models/prayer.model';

// import {AngularFirestore} from '@angular/fire/compat/firestore';
import { Firestore, doc, docData } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root',
})
export class GlobalVars {

    // TODO : toggle production flag
    // public isProduction  : boolean = false;
    // public isProduction  : boolean = true;
    public isProduction  : boolean = environment.production;

    public isTesting     : boolean = true;
    public escapeCode    : boolean;
    public filterEnabled : boolean;

    /* ==================================================================================================== */
    /* Setting Related */
    public networkType        : string;
    public networkStatus      : boolean;
    public networkMonitor     : any;
    public lastPageRoute      : string;
    public currentPageID      : number;
    public canGoBack          : boolean;
    public currentVersion     : number;
    public fontSize           : number;
    public minDistance        : number;
    public secondLevel        : boolean;
    public counter            : number;
    public refreshData        : boolean;
    public logCounter         : any;
    public backToExit         : boolean;

    /* ==================================================================================================== */
    /* User Related */
    public currentUser        : User;
    public deviceID           : string;
    public isUpdated          : boolean;
    public isLocked           : boolean;
    public isProtected        : boolean;

    /* ==================================================================================================== */
    /* Festival Related */
    public cardKey            : string;
    public currentAmount      : number;
    public lentPromotion      : {};
    public lentStartEnd       : {};
    public forthButton        : string;
    public collectionLent = {
        // collections. prepare for future fetch from firebase instead of hardcode constants
        CARD_INFO : '2025-card-info',
        CARD_KEY  : '2025-card-key',
        CARD      : '2025-lent-cards',
        IDS       : '2025-lent-ids',
        LOGS      : '2025-lent-logs',
        ARCHIVES  : '2025-lent-archives',
        AMBITIONS : '2025-lent-ambitions',
    
        SETTING   : '2025-lent-setting',
        BACKUP    : '2025-lent-backup',
        BOUNDARIES  : 'festival-boundaries',  // Newly added for controling start and ending date from firestore
    };
    public lentCard$          : Observable<any>;

    /* ==================================================================================================== */
    /* Data Related */
    public pages              : Page[];
    public online_pages       : Page[];  // Pages that need network to display
    public native_pages       : Page[];  // Pages that should rendered natively
    public alerts             : Alert[];
    public notifications      : Notification[];
    public homeBanners        : Banner[];
    public posters            : Poster[];
    public homeGrids          : Grid[];
    public eventGrids         : Grid[];
    public dailyVerses        : Verse[];
    public devotions          : Devotion[];
    public devotionCollection : DevotionCollection[];
    public customDevotion     : Devotion;
    public hymnCategories     : HymnCategory[];
    public hymnCollection     : HymnPost[];
    public randomHymns        : HymnPost[];
    public shareItem          : ShareItem;
    public customValidations  : any;
    public defaultControl     : {};
    public flagControl        : {};
    public bannerControl      : {};
    public eventControl       : {};
    public posterControl      : {};
    public vhControl          : {};

    constructor(
        private platform            : Platform,
        private router              : Router,
        private device              : Device,
        private market              : Market,
        private socialSharing       : SocialSharing,
        private iab                 : InAppBrowser,
        public  apiService          : ApiService,
        public  logService          : LogService,
        public  dateService         : DateService,
        public  dataService         : DataService,
        public  memberService       : MemberService,
        public  networkService      : NetworkService,
        public  messageService      : MessageService,
        public  modalService        : ModalService,
//        protected fireStore         : AngularFirestore,  // Added for special handling of lent start end
        protected fireStore         : Firestore,  // Added for special handling of lent start end        
    ) {
        /* ==================================================================================================== */
        /* Setting Related */
        this.counter            = 0;
        this.minDistance        = 240;
        this.currentPageID      = 0;
        this.canGoBack          = true;
        this.backToExit         = false;
        this.secondLevel        = false;
        this.refreshData        = false;
        this.pages = [
            // Top Pages
            {id: 0 , parent_id: -1 , order: 0 , show: true , open: true  , online: false , native: true, menu_title: '首頁'   , page_title: '永光App' , icon: '' , url: '/home/tabs' , children: []} ,
            {id: 1 , parent_id: -1 , order: 1 , show: true , open: false , online: false , native: true, menu_title: '最新'   , page_title: '最新'    , icon: '' , url: ''             , children: []} ,
            {id: 2 , parent_id: -1 , order: 2 , show: true , open: false , online: false , native: true, menu_title: '敬虔生活'   , page_title: '敬虔生活'    , icon: '' , url: ''             , children: []} ,
            {id: 3 , parent_id: -1 , order: 3 , show: true , open: false , online: false , native: true, menu_title: '教會'   , page_title: '教會'    , icon: '' , url: ''             , children: []} ,
            {id: 4 , parent_id: -1 , order: 4 , show: true , open: false , online: false , native: true, menu_title: 'e'    , page_title: 'e'     , icon: '' , url: ''             , children: []} ,
            {id: 5 , parent_id: -1 , order: 5 , show: true , open: false , online: false , native: true, menu_title: '設定'   , page_title: '設定'    , icon: '' , url: ''             , children: []} ,
            {id: 6 , parent_id: -1 , order: 6 , show: true , open: false , online: false , native: true, menu_title: '我的帳號' , page_title: '我的帳號'  , icon: '' , url: ''             , children: []} ,
            // Group 1 : News Pages
            {id: 10 , parent_id: 1 , order: 0 , show: true  , open: false , online: false , native: true, menu_title: '我的通知'     , page_title: '我的通知'     , icon: 'assets/icons/menu/ic_news.png' , url: '/notifications'               , children: []} ,
            {id: 11 , parent_id: 1 , order: 1 , show: true  , open: false , online: true  , native: true, menu_title: '最新聚會'     , page_title: '最新聚會'     , icon: ''                              , url: 'https://www.wkphc.org/2020events/' , children: []} ,
            {id: 12 , parent_id: 1 , order: 2 , show: true  , open: false , online: true  , native: true, menu_title: '復活期'      , page_title: '復活期'      , icon: ''                              , url: 'https://rebrand.ly/wkfestival' , children: []} ,
            {id: 13 , parent_id: 1 , order: 3 , show: true  , open: false , online: true  , native: true, menu_title: '饑饉卡'      , page_title: '饑饉卡'      , icon: ''                              , url: '/lentCard/tabs'               , children: []} ,
            {id: 14 , parent_id: 1 , order: 4 , show: true  , open: false , online: true  , native: true, menu_title: '詩歌靈修'     , page_title: '詩歌靈修'     , icon: ''                             , url: '/devotionCollection/tabs'     , children: []} ,
            {id: 15 , parent_id: 1 , order: 5 , show: false , open: false , online: true  , native: true, menu_title: '詩生活'      , page_title: '詩生活'      , icon: ''                              , url: '/hymn'                        , children: []} ,
            {id: 16 , parent_id: 1 , order: 6 , show: true  , open: false , online: true  , native: true, menu_title: '惡劣天氣應變措施' , page_title: '惡劣天氣應變措施' , icon: ''                              , url: 'https://www.wkphc.org/267/'   , children: []} ,
            // Group 2: Life Pages
            {id: 20 , parent_id: 2 , order: 20 , show: true , open: false , online: false , native: true, menu_title: '每日聖言' , page_title: '每日聖言' , icon: 'assets/icons/menu/ic_bible.png'    , url: '/bible'       , children: []} ,
            {id: 21 , parent_id: 2 , order: 21 , show: true , open: false , online: false , native: true, menu_title: '每日靈修' , page_title: '每日靈修' , icon: 'assets/icons/menu/ic_devotion.png' , url: '/devotion'    , children: []} ,
            {id: 22 , parent_id: 2 , order: 22 , show: true , open: false , online: false , native: true, menu_title: '每週禱文' , page_title: '每週禱文' , icon: 'assets/icons/menu/ic_pray.png'     , url: '/prayer/tabs' , children: []} ,
            // Group 3: Church Pages
            {id: 30 , parent_id: 3 , order: 30 , show: true , open: false , online: false , native: true, menu_title: '牧者分享'  , page_title: '牧者分享'  , icon: 'assets/icons/menu/ic_pastor.png'           , url: '/pastor'                                 , children: []} ,
            {id: 31 , parent_id: 3 , order: 31 , show: true , open: false , online: false , native: true, menu_title: '大堂報告'  , page_title: '大堂報告'  , icon: 'assets/icons/menu/ic_announcement.png'     , url: '/announcement'                           , children: []} ,
            {id: 32 , parent_id: 3 , order: 32 , show: true , open: false , online: true  , native: true, menu_title: '大堂行事曆' , page_title: '大堂行事曆' , icon: 'assets/icons/menu/ic_calendar.png'         , url: 'https://www.wkphc.org/calendar/'         , children: []} ,
            {id: 33 , parent_id: 3 , order: 33 , show: true , open: false , online: true  , native: true, menu_title: '報名'    , page_title: '報名'    , icon: 'assets/icons/menu/ic_application_list.png' , url: 'https://www.wkphc.org/application_list/' , children: []} ,
            {id: 34 , parent_id: 3 , order: 34 , show: true , open: false , online: false , native: true, menu_title: '縱橫'    , page_title: '縱橫'    , icon: 'assets/icons/menu/ic_vh.png'               , url: '/vh'                                     , children: []} ,
            {id: 35 , parent_id: 3 , order: 35 , show: true , open: false , online: false , native: true, menu_title: '節期'    , page_title: '節期'    , icon: 'assets/icons/menu/ic_church.png'           , url: '/festivals'                              , children: []} ,
            // Group 4: e Pages
            {id: 40 , parent_id: 4 , order: 40 , show: true , open: false , online: true , native: true, menu_title: 'e場地' , page_title: 'e場地' , icon: '' , url: 'https://www.wkphc.org/r/'    , children: []} ,
            {id: 41 , parent_id: 4 , order: 41 , show: true , open: false , online: true , native: true, menu_title: 'e詩聽' , page_title: 'e詩聽' , icon: '' , url: 'https://hymn.wkphc.org/'     , children: []} ,
            {id: 42 , parent_id: 4 , order: 42 , show: true , open: false , online: true , native: true, menu_title: 'e時填' , page_title: 'e時填' , icon: '' , url: 'https://offering.wkphc.org/' , children: []} ,
            {id: 43 , parent_id: 4 , order: 43 , show: true , open: false , online: true , native: true, menu_title: 'e平台' , page_title: 'e平台' , icon: '' , url: 'https://missionteam.wkphc.org/`' , children: []} ,
            // Group 5: Settings Pages
            {id: 50 , parent_id: 5 , order: 58 , show: false , open: false , online: false , native: true, menu_title: '登入'   , page_title: '登入'   , icon: 'assets/icons/menu/ic_login.png'    , url: '/login'    , children: []} ,
            {id: 51 , parent_id: 5 , order: 59 , show: false , open: false , online: false , native: true, menu_title: '登出'   , page_title: '登出'   , icon: 'assets/icons/menu/ic_logout.png'   , url: '/logout'   , children: []} ,
            {id: 52 , parent_id: 5 , order: 52 , show: true  , open: false , online: false , native: true, menu_title: '常見問題' , page_title: '常見問題' , icon: 'assets/icons/menu/ic_faq.png'      , url: '/faq'      , children: []} ,
            {id: 53 , parent_id: 5 , order: 53 , show: true  , open: false , online: false , native: true, menu_title: '系統設定' , page_title: '系統設定' , icon: 'assets/icons/menu/ic_settings.png' , url: '/settings' , children: []} ,
            {id: 54 , parent_id: 5 , order: 54 , show: true  , open: false , online: false , native: true, menu_title: '聯絡我們' , page_title: '聯絡我們' , icon: 'assets/icons/menu/ic_feedback.png' , url: '/feedback' , children: []} ,
            // {id: 55 , parent_id: 5 , order: 55 , show: true  , open: false , online: false , menu_title: '免責聲明' , page_title: '免責聲明' , icon: 'assets/icons/menu/ic_disclaimer.png' , url: '/disclaimer' , children: []} ,
            // Group 6: Member Pages
            {id: 60 , parent_id: 6 , order: 60 , show: true , open: false , online: true , native: true, menu_title: '個人資料'  , page_title: '個人資料'  , icon: 'assets/icons/menu/ic_profile.png' , url: '/profile' , children: []} ,
            {id: 61 , parent_id: 6 , order: 61 , show: true , open: false , online: true , native: true, menu_title: '家員證增值' , page_title: '家員證增值' , icon: 'assets/icons/menu/ic_barcode.png' , url: '/card'    , children: []} ,
        ];

        this.currentUser        = User.init({});
        /* ==================================================================================================== */
        /* Pages Related */
        this.homeBanners        = [];
        this.posters            = [];
        this.bannerControl      = {autoplay: false, initialSlide: 0, current: ''};
        this.eventControl       = {gridPerRow: 2};
        this.posterControl      = {limit: 10};
        this.vhControl          = {limit: 10};
        this.defaultControl     = {};
        this.flagControl        = {showCard: true, showPoster: false, allowUpload: false, forceUpdate: false};
        this.dailyVerses        = [];
        this.hymnCategories     = [];
        this.hymnCollection     = [];
        this.shareItem          = new ShareItem('', '', '', '');
        this.customValidations  = {
            normal        : {message: '' },
            required      : {message: '必須填寫' },
            validUsername : {message: '請輸入有效的e電園帳號' },
            validPassword : {message: '請輸入有效的密碼' },
            validFullname : {message: '請輸入中文全名' },
            validEmail    : {message: '請輸入有效的電郵' },
            validPhone    : {message: '請輸入8位數字的聯絡電話' },
            validCode     : {message: '請輸入5位數字的電腦密碼' },
            validTicket   : {message: '請輸入有效的增值券號碼' },
            saveOnce      : {message: '儲存後，將不能更改' },
            isProtected   : {message: '已不能更改' },
            available     : {message: '如適用' },
            different     : {message: '(如未輸入個人資料)' },
        };
        /* ==================================================================================================== */
        /* Festival Related */
        this.cardKey       = '';
        this.currentAmount = 0.0;
    }

    getAppVersion() {
        return '2.0.67';
    }

    getBuildVersion() {
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

        // return 248; // DEVELOPMENT version : 248
        return 267; // PRODUCTION version : 253

        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    }

    getLogVersion(type: string) {
        switch (type) {
            case Constants.LOG.system.DEVICE_ID    : return this.deviceID     || null;
            case Constants.LOG.system.DEVICE_MODEL : return this.device.model || null;
            case Constants.LOG.system.OS           : return (this.device.platform) ? `${this.device.platform} ${this.device.version}` : null;
            case Constants.LOG.system.APP          : return this.getAppVersion();
        }
    }

    getForceLogoutId() {
        return 28063;
    }

    getFontSize() {
        return this.fontSize;
    }

    /* ==================================================================================================== */

    /* PAGES & ROUTES */

    getPages(): Page[] {
        return this.pages.filter(item => item.parent_id == -1);
    }
    getPageByID(id: number) {
        let target = this.pages.find(item => item.id === id);
        if (target === undefined) {
            return Page.init({});
        }
        return target;
    }
    getPageByURL(url: string) {
        return this.pages.find(item => item.url === url);
    }

    sortPages() {
        this.sortByField(this.pages, Constants.SORT_ORDER);
        // Mount Page Children
        this.pages.forEach(page => {
            this.getPageByID(page.id).children = (page.parent_id == -1) ? this.pages.filter(item => item.parent_id == page.id) : [];
        });
    }

    /*
    * PAGE SETUP
    ! native pages
    ? news:        10, 13, 14, 15
    ? devotion:    20, 21, 22
    ? church:      30, 31, 34, 35
    ? e:           43, 44
    ? settings:    50, 52, 53
    ? member:      60, 61,
    ? festival:    99,
    ! Local pages: 80 - 89,
    ! Web pages:   91 - 98,
    */
    goToPageByID(id: number) {
        // handle network status
        if (
            this.isApp() && (
                this.getNetworkType() == this.networkService.network.Connection.NONE ||
                this.getNetworkType() == this.networkService.network.Connection.UNKNOWN
            )
        ) {
            if (this.online_pages.map(item => item.id).includes(id)) {
                return this.presentNetworkAlert(false);
            }
        }

        let page = this.getPageByID(id);

        let specialPages = [
            51, 61, 13
        ];
        let nativePages = [
            0,
            10, 13, 14, 15, 19,
            20, 21, 22, 23, 24, 25, 26,
            30, 31, 34, 35,
            43, 44,
            50, 52, 53,
            60, 61,
            72, 73,
            99,
        ];
        if (specialPages.includes(page.id)) {
            // Go Native
            switch (id) {
                // special operations
                case 51:
                    this.presentLogoutAlert();
                    return;
                case 61:
                    if (this.isEmpty(this.currentUser.username)) {
                        this.presentMemberOnlyAlert();
                        return;
                    } else {
                        this.openPage(page.url, page.page_title);
                        break;
                    }
                case 13:  // Lent card. Will check if card exists in local data.
                    this.openLentCard();
                    break;
            }
        }
        // else if (nativePages.includes(page.id) || (page.id >= 80 && page.id < 90)) {
        else if (page.native) {
                // Go Native
            this.openPage(page.url, page.page_title);
        }
        else if (this.isWebContent(page.url)  || (page.id >= 90)) {
            // Go Browser
            this.browseURL(page.url);
        }

        this.currentPageID = id;
        this.refreshMenu();
    }
    goToPageByURL(url: string, params: any) {
        let page = this.getPageByURL(url);
        this.currentPageID = page.id;
        this.router.navigate([url, params])
            .then(() => this.refreshMenu());
    }
    openPage(url: string, title: string) {
        if (this.isWebContent(url)) {
            this.router.navigate(['/web', {url: url, title: title}])
                .then(() => {});
        } else {
            this.router.navigate([url])
                .then(() => {});
        }
    }
    openPageByURL(link: string, title: string = '') {
        // no action if link is empty
        if (this.isEmpty(link)) { return; }
        let params = { url: link, title: title };

        // open browser if web / undefined pages
        if (this.isWebContent(params.url)) {
            this.browseURL(params.url);
            return;
        }

        // handle page link if native pages
        if (params.url.includes(',')) {
            let url_parts  = params.url.split(',');
            switch (url_parts[0]) {
                case 'route':
                    let target = this.pages.find(item => item.url == url_parts[1]);
                    if (target == undefined) {
                        this.router.navigateByUrl(url_parts[1])
                            .then(() => {});
                    } else {
                        this.goToPageByID(target.id);
                    }
                    break;
                case 'iab':
                    this.browseURL(url_parts[1]);
                    break;
                case 'web':
                    this.router.navigate(['/web', params])
                        .then(() => {});
                    break;
            }
        } else {
            let target = this.pages.find(item => item.url == params.url);
            this.goToPageByID(target.id);
        }
    }

    backPage(url: string) {
        this.router.navigateByUrl(url)
            .then(() => this.refreshMenu());
    }

    refreshMenu() {
        // TODO: handle menu blink
        let childPage  = this.getPageByID(this.currentPageID);
        let parentPage = this.getPageByID(childPage.parent_id);
        this.pages.forEach((page: Page) => page.open = (page.id === childPage.id));
        if (parentPage !== undefined) {
            parentPage.open = true;
        }
    }

    fetchAppMenu() {
        this.apiService.settingsService.getAppMenu()
            .subscribe((value: Page[]) => {
                // handle visible pages
                this.pages = value.filter(item => item.show === true);
                this.sortPages();
                this.refreshMenu();
                this.toggleLoggedMenuItem();
                // handle online pages
                this.online_pages = this.pages.filter(item => item.online == true);
                this.native_pages = this.pages.filter(item => item.native == true);
            });
    }

    /* ==================================================================================================== */

    /* Getters */

    calculateOfferingTotal() {
        // console.log('calculate offering total.');
        let currentTotal = 0.0;
        this.dataService.recordSub = this.dataService.lentRecord$
            .subscribe((items: LentEntry[][]) => {
                currentTotal = 0.0;
                for (let i = 0; i < items.length; i++) {
                    let recordArray = items[i];
                    if (recordArray !== undefined) {
                        for (let j = 0; j < recordArray.length; j++) {
                            let record = recordArray[j];
                            currentTotal += Number(record.amount);
                        }
                    }
                }
                // console.log('currentTotal: ', currentTotal);

                this.apiService.festivalService.localCard$
                    .subscribe(card => {
                        let newTotal = Number(Number(currentTotal).toFixed(1));
                        let updateParams = {
                            key: this.cardKey,
                            entries: card.entries,
                            total: newTotal,
                            last_update: this.dateService.getCurrentTime(),
                        };
                        if (card.total !== newTotal) {
                            card.total = newTotal;
                            // console.log('local card total: ', card.total);
                            this.apiService.festivalService.updateOfferingTotal(updateParams);
                        } else {
                            // console.log('same total, do nothing.');
                        }
                    });
            });
        this.dataService.recordSub.unsubscribe();
    }

    /**
     * fetch Various app data from Firestore.
     * 
     */
    async fetchData() {
        // if (this.isTesting) {
        //     await this.fetchAppMenu();
        //     await this.fetchFestivalResources();
        // //     await this.fetchFestivalItems();
        // //     await this.fetchHomeStuff();
        // //     await this.fetchMeetings();
        //     await this.fetchDevotionContent();
        //     console.log('test run, only loads limited data.');
        //     return;
        // }
        // ---------- Fetch Firestore Data ---------- //
        await this.fetchAppMenu(); // !
        await this.fetchAppSettings();
        await this.fetchLevels();
        await this.fetchAlerts();
        await this.fetchMeetings(); // !
        await this.fetchNotifications();
        await this.fetchDailyVerses();
        await this.fetchHomeStuff(); // !
        await this.fetchFestivalResources(); // !
        await this.fetchFestivalItems(); // !
        await this.fetchDevotionCollection();
        // ---------- Fetch Server Data ---------- //
        await this.fetchPosters();
        await this.fetchDevotionContent();
        await this.fetchPrayerContent();
        // await this.fetchHymnContent();
        await this.fetchPastorSharing();
        await this.fetchVertiHoriSharing(this.vhControl['limit']);
        await this.fetchAnnouncementContent();
    }

    /* ==================================================================================================== */

    /* SYSTEM */

    getLogInfo() : Log {
        return Log.init({
            device_id    : this.getLogVersion(Constants.LOG.system.DEVICE_ID),
            device_model : this.getLogVersion(Constants.LOG.system.DEVICE_MODEL),
            os_version   : this.getLogVersion(Constants.LOG.system.OS),
            app_version  : this.getLogVersion(Constants.LOG.system.APP),
        });
    }

    getNetworkType(): string {
        return this.networkService.getNetworkType();
    }

    getNetworkStatus(): Observable<boolean> {
        return this.networkService.getNetworkStatus();
    }

    isNetworkAvailable() {
        return (this.getNetworkType() !== this.networkService.network.Connection.NONE &&
        this.getNetworkType() !== this.networkService.network.Connection.UNKNOWN);
    }

    subscribeConnection() {
        if (!this.isApp()) { return; }
        this.networkMonitor = this.networkService
            .getNetworkStatus()
            .pipe(debounceTime(300))
            .subscribe((connected: boolean) => {
                this.networkType = this.getNetworkType();
                this.networkStatus = connected;
                if (!this.networkStatus) {
                    this.presentNetworkAlert(true);
                } else {
                    if (this.refreshData) {
                        this.fetchData()
                            .then(() => {});
                    }
                }
            });
    }

    unsubscribeConnection() {
        if (!this.isApp()) { return; }
        if (this.networkMonitor !== null) {
            this.networkMonitor.unsubscribe();
            this.networkMonitor = null;
        }
    }

    /* ==================================================================================================== */

    /* AUTH */

    login(user: any) {
        return this.memberService.login(user);
    }

    async logoutWithPage(id: number) {
        this.presentLoading();
        this.memberService.toggleAuthStatus(false);
        setTimeout(() => {
            this.dismissLoading()
                .then(() => {
                    // user handling
                    this.removeCurrentUser();
                    this.isUpdated = false;
                    // page handling
                    this.pages.forEach(page => page.open = false);
                    this.getPageByID(id).open = true;
                    this.goToPageByURL(this.getPageByID(id).url, {});
                });
        }, 2000);
    }

    isMember() {
        return this.currentUser.identity === Constants.IDENTITY.MEMBER;
    }

    getUserParams() {
        return {
            id             : this.currentUser.id,
            username       : (this.currentUser.identity === Constants.IDENTITY.MEMBER) ? this.currentUser.username : this.currentUser.email,
            device         : this.deviceID,
            fullname       : this.currentUser.fullname,
            email          : this.currentUser.email,
            phone          : this.currentUser.phone,
            offering_code  : this.currentUser.offering_code,
            family         : this.currentUser.family,
            unit           : this.currentUser.unit,
            identity       : this.currentUser.identity,
            balance        : Number(this.currentUser.balance),
            last_login     : this.currentUser.last_login,
            last_update    : this.dateService.getCurrentTime(),
            device_version : this.getLogVersion(Constants.LOG.system.DEVICE_MODEL),
            os_version     : this.getLogVersion(Constants.LOG.system.OS),
            app_version    : this.getLogVersion(Constants.LOG.system.APP),
        };
    }

    removeCurrentUser() {
        // this.currentUser = new User(-1, '', '' , '', '', '', '', '' , '', '', 0, '', '', '', '');
        this.currentUser = User.init({});
        this.dataService.removeData(Constants.KEY_CURRENT_USER)
            .then(() => console.log('user data cleared.'));
    }

    emptyProfile() {
        let mandatoryFields = [
            this.currentUser.fullname,
            this.currentUser.email,
            this.currentUser.family,
        ];
        return this.checkEmpty(mandatoryFields);
    }

    /**
     * Check if user has logged in from another device.
     */
    checkSingleLogin() {
        let loggedIn = this.memberService.checkSingleLogin({username: this.currentUser.username});
        let loginSubscription: Subscription;
        if (loggedIn)
            loginSubscription = loggedIn.subscribe(data => {
                if (data !== null) {
                    if (data.device !== this.deviceID) {
                        this.messageService.presentOptionsAlert(
                            '你已在多個裝置上登入！',
                            '',
                            '請選擇現在要使用的裝置：',
                            [
                                {
                                    text: '之前的',
                                    role: 'cancel',
                                    cssClass: 'secondary',
                                    handler: () => {
                                        this.logoutWithPage(0)
                                            .then(() => {
                                                loginSubscription.unsubscribe();
                                            });
                                    }
                                },
                                {
                                    text: '現在的',
                                    cssClass: 'caution',
                                    handler: () => {
                                        this.updateUserProfile()
                                            .then(() => {});
                                    }
                                }
                            ]
                        );
                    }
                }
            });
    }

    /* ==================================================================================================== */

    /* Utils */

    isMobile() {
        let mobile = ['ios', 'android', 'mobile', 'mobileweb'];
        if (this.platform.platforms().some(item => mobile.includes(item))) {
            return true;
        } else if (this.platform.is('desktop')) {
            return false;
        }
    }

    isIOS() {
        return this.platform.is('ios');
    }

    isAndroid() {
        return this.platform.is('android');
    }

    isApp() {
        return (window.location.port !== '8100');
    }

    isWebContent(content: string) {
        return content.startsWith('http://') || content.startsWith('https://');
    }

    isAppContent(content: string) {
        return content.startsWith('app,');
    }

    /**
     * Check local app version with version info fetched from Firebase
     * @param info buildInfo
     * @returns 
     */
    isUpdatePending(info: any) {
        this.currentVersion = info.version;
        if (this.isIOS()) {
            this.currentVersion = info.iosVersion;
        } else if (this.isAndroid()) {
            this.currentVersion = info.androidVersion;
        }
        return this.currentVersion > this.getBuildVersion();
    }

    /* Utils - Messages */

    presentToast(message) {
        this.messageService.presentToast(message)
            .then(() => {});
    }

    async presentLoading() {
        await this.messageService.presentLoading();
    }

    presentLoadingWithDuration(handler, duration) {
        this.messageService.presentLoading();
        setTimeout(handler, duration);
    }

    async dismissLoading() {
        await this.messageService.dismissLoading();
    }

    presentAlert(header, subheader, message, buttons) {
        this.messageService.presentGeneralAlert(header, subheader, message, buttons)
            .then(() => {});
    }

    presentNotificationAlert(header, message, positiveHandler) {
        let buttons = [{
            text: '好的',
            handler: positiveHandler
        }];
        this.messageService.presentGeneralAlert(header, '', message, buttons)
            .then(() => {});
    }

    /**
     * Present a confirmation alert
     * TODO: negative handler
     * @param header 
     * @param message 
     * @param positiveHandler 
     * @param completedHandler
     */
    presentConfirmationAlert(header:string, message:string, positiveHandler:()=>void, completedHandler:()=>void) {
        let buttons = [
            {
                text: '取消',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {
                    this.backToExit = false;
                }
            },
            {
                text: '確認',
                handler: positiveHandler
            }
        ];
        this.messageService.presentGeneralAlert(header, '', message, buttons)
            .then(() => {
                completedHandler();
            });
    }

    async presentDestructiveAlert(header, message, positiveHandler) {
        let buttons = [
            {
                text: '取消',
                role: 'cancel',
                cssClass: 'secondary',
                handler: () => {}
            },
            {
                text: '確認',
                cssClass: 'caution',
                handler: positiveHandler
            }
        ];
        await this.messageService.presentGeneralAlert(header, '', message, buttons);
    }

    presentNetworkAlert(action: boolean) {
        let params = {header: '', message: '', handler: {}};
        if (action) {
            params.header = '網絡連線不穩定！';
            params.message = '請確認網絡連線狀態。';
            params.handler = () => {
                if (this.router.url != this.getPageByID(0).url) {
                    this.goToPageByURL(this.getPageByID(0).url, {});
                }
            };
        } else {
            params.header = '未能存取頁面！';
            params.message = '請確保網絡連線正常，並重新載入。';
            params.handler = () => {
                return;
            };
        }
        return this.presentNotificationAlert(
            params.header,
            params.message,
            params.handler,
        );
    }

    presentTimeoutAlert(header, subheader, message, buttons, followUp) {
        this.messageService.presentTimeoutAlert(header, subheader, message, buttons)
            .then(followUp);
    }

    presentLogoutAlert() {
        this.presentConfirmationAlert(
            '即將登出',
            '稍後再登入永光App？',
            () => {
                this.logoutWithPage(0)
                    .then(() => {});
            },
            () => { this.backToExit = true },
            );
    }

    async presentForceUpdateAlert(force: boolean) {
        let alertOptions = {
            header: '永光App 2.0',
            subheader: '新的版本已可供下載',
            message: (force) ? '如要繼續使用永光App，必須先進行版本更新' : '',
            buttons: [
                {
                    text: '稍後',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        if (force) {
                            this.dataService.isPrompting = false;
                            navigator['app'].exitApp();
                        }
                    }
                },
                {
                    text: '立即更新',
                    handler: () => {
                        this.dataService.isPrompting = false;
                        if (this.isIOS()) {
                            this.market.open(Constants.APP_ID.ios);
                        } else if (this.isAndroid()) {
                            this.market.open(Constants.APP_ID.android);
                        }
                    }
                }
            ],
        }
        if (force && this.isIOS()) {
            alertOptions.buttons.shift();
        }
        if (!this.dataService.isPrompting) {
            this.presentAlert(
                alertOptions.header,
                alertOptions.subheader,
                alertOptions.message,
                alertOptions.buttons,
            );
            this.dataService.isPrompting = true;
        }
    }

    presentForceLogoutAlert(value: number) {
        this.presentNotificationAlert(
            '應用程式有更新',
            '請重新登入，以使用最新的功能。',
            () => {
                this.logoutWithPage(0)
                    .then(() => {
                        this.dataService.setData(Constants.KEY_FORCE_LOGOUT, value)
                            .then(() => {});
                    });
            },
        );
    }

    presentMemberOnlyAlert() {
        this.messageService.presentOptionsAlert(
            '未能存取！',
            '假如你是家員，請以家員身份重新登入。',
            '此功能只開放給家員使用。',
            [
                {
                    text: '重新登入',
                    cssClass: 'caution',
                    handler: () => {
                        this.logoutWithPage(50)
                            .then(() => {});
                    }
                },
                {
                    text: '好的',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                }
            ]
        )
            .then(() => {});
    }

    presentPromptAlert(header, meessage, inputs, buttons) {
        this.messageService.presentPromptAlert(header, meessage, inputs, buttons)
            .then(() => {});
    }

    presentGoAlert(header, subheader, positiveHandler) {
        this.presentAlert(
            header,
            subheader,
            '',
            [
                {
                    text: '稍後',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {}
                },
                {
                    text: '前往',
                    handler: positiveHandler
                }
            ],
        );
    }

    presentSharePopover(params: any) {
        // this.messageService.presentPopover({
        //     title : params.title,
        //     link  : params.link,
        // })
        //     .then(() => {});
    }

    presentImageModal(img, url = '') {
        if (this.isEmpty(url)) {
            this.modalService.presentImageModal(img)
                .then(() => {});
        } else {
            this.browseURL(url);
        }
    }

    presentSharePicker(message: string, subject: string, file: string, url: string,
                       positiveHandler = {}) {
        this.socialSharing.share(message, subject, file, url)
            .then(data => positiveHandler);
    }

    browseURL(url: string) {
        if (url !== '' && url.length > 0) {
            this.iab.create(url, '_system', 'location=no');
        }
    }

    isEmpty(content) {
        return content == null || content.length == 0;
    }

    checkEmpty(array: any) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].length == 0) {
                return true;
            }
        }
        return false;
    }

    checkEqual(array1: any, array2: any) {
        if (!(array1 instanceof Array) || !(array2 instanceof Array)) {
            return false;
        }
        return !(
            array1.length === array2.length &&
            array1.sort().every((index, value) => value === array2.sort()[index])
        );
    }

    // removeDuplicates(array) {
    //     // if (!this.isEmpty(array)) {
    //         console.log("array remove duplicates.");
    //         return Array.from(new Set(array));
    //     // }
    // }

    sortByField(array, field) {
        switch (field) {
            case Constants.SORT_ID:
                array.sort((a, b) => {
                    if (a.id < b.id) return -1;
                    else if (b.id < a.id) return 1;
                    return 0;
                });
                break;
            case Constants.SORT_DATE:
                array.sort((a, b) => {
                    if (a.date < b.date) return 1;
                    else if (b.date < a.date) return -1;
                    return 0;
                });
                break;
            case Constants.SORT_ORDER:
                array.sort((a, b) => {
                    if (a.order < b.order) return -1;
                    else if (b.order < a.order) return 1;
                    return 0;
                });
                break;
            case Constants.SORT_UPDATE:
                array.sort((a, b) => {
                    if (a.last_update < b.last_update) return 1;
                    else if (b.last_update < a.last_update) return -1;
                    return 0;
                });
                break;
        }
    }

    toggleLoggedMenuItem() {
        // id: 50 - Login , 51 - Logout, 60 - profile, 61 - card,
        this.memberService.authenticationStatus
            .subscribe(status => {
                this.getPageByID(6).show  = status;
                this.getPageByID(50).show = !status;
                this.getPageByID(51).show = status;
                this.getPageByID(60).show = status;
                this.getPageByID(61).show = status;
            });
    }

    extractImgURL(src: string) : string {
        var snippet = document.createElement("div");
        snippet.innerHTML = src;
        console.log('snippet: ', snippet);
        var links = snippet.getElementsByTagName("a");
        console.log('link: ', links);
        return links[links.length-1].href;
    }

    /* ==================================================================================================== */

    /* APIs */

    async fetchApiControls() {
        this.dataService.apiControls$ = this.apiService.settingsService.getApiControls();
        this.dataService.apiControls$
            .subscribe(items => {
                this.dataService.setData(Constants.COLLECTION_API_CONTROLS, items)
                    .then(() => {
                        console.log('api controls: ', items);
                    });
            });
    }

    logApiActions() {
        if (this.logCounter === undefined) {
            // Constants.TIMEOUT_MEDIUM
            // 20000
            this.logCounter = interval(Constants.TIMEOUT_MEDIUM)
                .pipe(startWith(0))
                .subscribe(() => {
                    this.logService.pushAPILogs();
                    // if (this.isTesting) {
                    //     this.notificationService.sendNotification();
                    // }
                });
        }
    }

    async fetchAppSettings() {
        await this.dataService.getData(Constants.KEY_FONT_SIZE)
            .then(value => {
                this.fontSize = Number(value) || Constants.MAIN_FONT_SIZE;
            });
        await this.apiService.settingsService.getAppSettings()
            .subscribe(settings => {
                if (settings !== null) {
                    console.log('settings: ', settings);

                    // 0. Save Settings
                    this.dataService.setData(Constants.KEY_APP_SETTINGS, settings)
                        .then(() => {
                            /*
                            ! when updated, fetch corresponding api
                            */
                            // console.log('settings: ', settings);
                        });

                    // 1. Build Version
                    if (this.isMobile()) {
                        this.dataService.updatePending = this.isUpdatePending(settings.buildInfo);
                        if (this.dataService.updatePending) {
                            this.presentForceUpdateAlert(settings.flagControl['forceUpdate']);
                        }
                    }
                    // 2. Force Logout
                    let web_id = Number(settings.defaultControl.forceLogout);
                    if (web_id !== this.getForceLogoutId()) {
                        this.dataService.getData(Constants.KEY_FORCE_LOGOUT)
                            .then(id => {
                                if (id !== null) {
                                    let storage_id = Number(id);
                                    if (web_id !== storage_id) {
                                        this.presentForceLogoutAlert(web_id);
                                    }
                                } else {
                                    this.presentForceLogoutAlert(web_id);
                                }
                            });
                    }

                    // 3. Home Banners
                    this.bannerControl = settings.bannerControl;

                    // 4. Home Event Grids
                    this.eventControl = settings.eventControl;

                    // 5. Home Poster
                    this.posterControl = settings.posterControl;

                    // 6. VH Sharing
                    this.vhControl = settings.vhControl;

                    // 7. Escape Code
                    this.escapeCode = settings.flagControl.escapeCode;

                    this.defaultControl = settings.defaultControl;
                    this.flagControl    = settings.flagControl;
                } else {
                    console.log("Error: null settings!");
                }
            });
    }

    async refreshToken() {
        const res = await this.memberService.refreshToken(this.currentUser.refresh_token)
        if (res.status === 200) {
            let result = (res.data);
            this.currentUser.access_token  = result.access_token;
            this.currentUser.refresh_token = result.refresh_token;
        } else {
            this.currentUser.access_token  = '';
            this.currentUser.refresh_token = '';
        }
    }

    async checkUser(token: any) {
        const user = await this.memberService.checkUser(token.access_token)
        if (user.status === 200) {
            try {
                let result = user.data;

                this.currentUser.id          = result.id;
                this.currentUser.username    = result.username;
                this.currentUser.email       = result.email;
                this.currentUser.phone       = result.meta.phone;
                this.currentUser.fullname    = result.first_name;
                this.currentUser.family      = result.last_name;
                this.currentUser.unit        = result.meta.unit;
                this.currentUser.identity    = result.meta.status;
                this.currentUser.barcode     = result.meta.barcode;
                this.currentUser.balance     = result.meta.balance;
                this.currentUser.last_login  = this.dateService.getCurrentTime();
                this.currentUser.last_update = result.meta.last_update;

                const userProfileResult = await this.fetchUserProfile({ username: this.currentUser.username });
                if (userProfileResult.status != undefined && userProfileResult.status === 200) {
                    let result = userProfileResult.data.data;
                    this.currentUser.fullname    = result.name;
                    this.currentUser.family      = result.family;
                    this.currentUser.unit        = result.unit;
                    this.currentUser.barcode     = result.barcode;
                    this.currentUser.balance     = result.balance;
                    this.currentUser.identity    = (result.status == Constants.STATUS.ME) ? Constants.IDENTITY.MEMBER : Constants.IDENTITY.GUEST;
                    this.currentUser.last_update = this.dateService.getCurrentTime();

                    // Update LocalStorage
                    this.dataService.setData(Constants.KEY_CURRENT_USER, this.currentUser).then(() => {});
                    // Update WordPress
                    const response = await this.memberService.updateMember({
                        id          : this.currentUser.id,
                        email       : this.currentUser.email,
                        phone       : this.currentUser.phone,
                        unit        : this.currentUser.unit,
                        barcode     : this.currentUser.barcode,
                        balance     : this.currentUser.balance,
                        status      : this.currentUser.identity,
                        last_login  : this.currentUser.last_login,
                        last_update : this.currentUser.last_update,
                    });
                    if (response.status != undefined && response.status === 200) {
                        let result = (response.data);
                        if (!this.isEmpty(result)) {}
                    }
                }
            } catch (e) {
                this.currentUser = User.init({});
            }
        }
    }

    async fetchUserProfile(args: any) {
        return await this.memberService.fetchUserProfile(args);
    }

    // async readUserProfile(args: any) {
    //     return await this.memberService.readMember(args);
    // }

    async updateUserProfile() {
        let args = this.getUserParams();
        await this.memberService.updateUserProfile(args, this.escapeCode);
    }

    async fetchCurrentBalance() {
        const response = await this.memberService.fetchBalance({username: this.currentUser.username})
        if (response.status != undefined && response.status === 200) {
            let result = (response.data);
            if (!this.isEmpty(result.data)) {
                this.currentUser.balance = Number(result.data);
            }
        }
    }

    // /* ================================================== */ //
    /* Basic */

    async fetchLevels() {
        this.dataService.levels$ = this.apiService.notificationService.getLevels();
        this.dataService.levels$
            .subscribe(items => {
                this.dataService.setData(Constants.COLLECTION_LEVELS, items)
                    .then(() => {});
            });
    }

    async fetchAlerts() {
        this.dataService.alerts$ = this.apiService.alertService.getAlerts()
            .pipe(
                map(items => items.filter(item => this.filterEnabled ? item.show && this.dateService.validDate(item.release_time, item.expired_time) : item)),
            );
        this.dataService.alerts$
            .subscribe(items => {
                this.alerts = items as Alert[];
                this.dataService.setData(Constants.COLLECTION_ALERTS, this.alerts)
                    .then(() => {});
            });
    }

    async fetchMeetings() {
        this.dataService.meetings$ = this.apiService.meetingService.getMeetings();
        this.dataService.meetings$
            .subscribe(() => {});
    }

    async fetchNotifications() {
        this.dataService.notifications$ = this.apiService.notificationService.getNotifications(this.filterEnabled);
    }

    async fetchDailyVerses() {
        // Grep today's verse.
        this.apiService.verseService.getDailyVerses()
            .subscribe((value: Verse[]) => this.dailyVerses = value);

        // Fetch 
        this.dataService.verses$ = this.apiService.verseService.getVerses();
        this.dataService.verses$
            .subscribe(() => {})
    }

    async fetchHomeStuff() {
        // // 1. Home Banners
        this.fetchHomeBanners();
        // // 2. Home Grids
        this.dataService.homeGrids$ = this.apiService.gridService.getHomeGrids();
        this.dataService.homeGrids$
            .subscribe((values: Grid[]) => {
                this.homeGrids = values;
                this.dataService.setData(Constants.COLLECTION_HOME_GRIDS, this.homeGrids);
            });
        // // 3. Event Grids
        this.dataService.eventGrids$ = this.apiService.gridService.getEventGrids(this.filterEnabled);
        this.dataService.eventGrids$
            .subscribe(values => {
                this.eventGrids = values as Grid[];
                this.dataService.setData(Constants.COLLECTION_EVENT_GRIDS, this.eventGrids);
            });
    }
    fetchHomeBanners() {
        this.dataService.homeBanners$ = this.apiService.bannerService.getBanners(this.filterEnabled, this.bannerControl['current']);
        this.dataService.homeBanners$
            .subscribe(items => {
                this.homeBanners = items as Banner[];
                this.dataService.setData(Constants.COLLECTION_BANNERS, this.homeBanners)
                    .then(() => {});
            });
    }

    async fetchPosters(length: number = 30) {
        this.dataService.posters$ = this.apiService.posterService.getPosterObservable(length);
        this.dataService.posters$
            .subscribe(items => {
                this.posters = items as Poster[];
                this.dataService.setData(Constants.KEY_POSTER, this.posters)
                    .then(() => {});
            });

    }

    // /* ================================================== */ //
    /* Devotion */

    async fetchDevotionContent() {
        await this.fetchDevotionFromApi();
    }

    async fetchDevotionFromApi() {
        // await console.log('Devotion Content - from api, per week');
        this.dataService.devotions$ = this.apiService.devotionService.getDevotionContentPerWeek();
        this.dataService.devotions$
            .subscribe(items => {
                this.devotions = items as Devotion[];
                this.dataService.setData(Constants.KEY_DEVOTION_CONTENT, this.devotions);
            });

        // TODO: get 2021 Hymn Devotion content
        this.dataService.hymnDevotions$ = this.apiService.devotionService.get2021HymnDevotion();
        this.dataService.hymnDevotions$
            .subscribe(items => {
                this.dataService.setData(Constants.KEY_2021_HYMN_DEVOTION, items);
            });
    }

    async fetchDevotionCollection() {
        this.dataService.devotionCollection$ = this.apiService.devotionService.getDevotionCollectionObservable();
        this.dataService.devotionCollection$
            .subscribe(items => {
                this.devotionCollection = items as DevotionCollection[];
                this.dataService.setData(Constants.COLLECTION_DEVOTION_COLLECTION, this.devotionCollection)
                    .then(() => {});
            });
    }

    // /* ================================================== */ //
    /* Prayer */

    async fetchPrayerContent() {
        await this.fetchWeeklyPrayer();
        await this.fetchWeeklyItem();
    }

    async fetchWeeklyPrayer() {
        this.dataService.weeklyPrayers$ = this.apiService.prayerService.getWeeklyPrayer();
        this.dataService.weeklyPrayers$
            .subscribe((items: Prayer[]) => {
                // console.log('api prayers posters: ', items);
                this.dataService.setData(Constants.KEY_WEEKLY_PRAYER, items)
                    .then(() => {});
            });
    }
    async fetchWeeklyItem() {
        this.dataService.weeklyItems$ = this.apiService.prayerService.getWeeklyItem();
        this.dataService.weeklyItems$
            .subscribe((items: Prayer[]) => {
                // console.log('api prayers items: ', items);
                this.dataService.setData(Constants.KEY_WEEKLY_ITEM, items)
                    .then(() => {});
            });
    }



    // /* ================================================== */ //
    /* Hymns */

    // async fetchHymnContent() {
    //     await this.apiService.hymnService.getHymnCategories()
    //         .subscribe(items => {
    //             this.hymnCategories = items as HymnCategory[];
    //             this.dataService.setData(Constants.KEY_HYMNS.CATEGORIES, this.hymnCategories)
    //                 .then(() => {});
    //             // console.log('hymn categories: ', this.hymnCategories);
    //         });
    //     await this.apiService.hymnService.getRandomHymns()
    //         .subscribe(items => {
    //             this.randomHymns = items as HymnPost[];
    //             this.dataService.setData(Constants.KEY_HYMNS.RANDOM, this.randomHymns)
    //                 .then(() => {});
    //             // console.log('random hymns: ', this.randomHymns);
    //             // this.presentToast(`hymns: ${JSON.stringify(this.randomHymns.map(item => item.title))}`);
    //         });
    //     await this.fetchNewHymns();
    // }
    //
    // async fetchNewHymns() {
    //     await this.apiService.hymnService.getHymns({
    //         categories: 5,
    //         amount: 20,
    //     })
    //         .subscribe(res => {
    //             this.hymnCollection = [];
    //             res.forEach(item => this.hymnCollection.push(HymnPost.init(item)));
    //             this.dataService.setData(Constants.KEY_HYMN_COLLECTION, this.hymnCollection)
    //                 .then(() => {});
    //         });
    // }

    // /* ================================================== */ //
    /* Sharing */

    async fetchPastorSharing() {
        this.dataService.pastorSharing$ = this.apiService.sharingService.getPastorSharing();
        this.dataService.pastorSharing$
            .pipe(
                take(1),
                distinctUntilChanged(),
            )
            .subscribe(items => {
                this.dataService.setData(Constants.KEY_PASTOR_SHARING, items);
            });
    }

    async fetchVertiHoriSharing(length: number = 10) {
        this.dataService.vhSharing$ = this.apiService.sharingService.getVertiHoriSharing(length);
        this.dataService.vhSharing$
            .pipe(
                take(1),
                distinctUntilChanged(),
            )
            .subscribe(items => {
                this.dataService.setData(Constants.KEY_VH_SHARING, items);
            });
    }

    // /* ================================================== */ //
    /* Announcements */

    async fetchAnnouncementContent() {
        this.dataService.announcements$ = this.apiService.announcementService.getAnnouncementObservable();
        this.dataService.announcements$
            .pipe(
                take(1),
                distinctUntilChanged(),
            )
            .subscribe(items => {
                this.dataService.setData(Constants.KEY_ANNOUNCEMENT_CONTENT, items);
            });
    }

    // /* ================================================== */ //
    /* Festivals - Lent */

    /**
     * Update festival date range. Called when firestore update.
     */
    updateFestivalDays(startEnd) {
        if (startEnd !== undefined) {
            this.lentStartEnd = startEnd;
            this.dataService.festivalDays = [];
            for (let i = 0; i < Constants.LENT_LENGTH; i++) {
                let date = moment(startEnd.LENT_START_DAY).add(i, 'days');
                this.dataService.festivalDays.push(new LentDate(i, date, date.days(), false));
            }
            this.dataService.lentCalendar = [];
            for (let i = 0; i < this.dataService.festivalDays.length; i+=Constants.WEEK_LENGTH) {
                let week = this.dataService.festivalDays.slice(i, i + Constants.WEEK_LENGTH);
                this.dataService.lentCalendar.push(week);
            }
        } else {
            console.log("Firebase Festival Boundary Error");
        }
    }

    async fetchFestivalDays() {
        // Init by constants. May change after fetching from firebase later.
        this.lentStartEnd = {"LENT_START_DAY":Constants.LENT_START_DAY, "LENT_END_DAY":Constants.LENT_END_DAY};
        this.dataService.festivalDays = [];
        for (let i = 0; i < Constants.LENT_LENGTH; i++) {
            let date = moment(this.lentStartEnd["LENT_START_DAY"]).add(i, 'days');
            this.dataService.festivalDays.push(new LentDate(i, date, date.days(), false));
        }
        this.dataService.lentCalendar = [];
        for (let i = 0; i < this.dataService.festivalDays.length; i+=Constants.WEEK_LENGTH) {
            let week = this.dataService.festivalDays.slice(i, i + Constants.WEEK_LENGTH);
            this.dataService.lentCalendar.push(week);
        }
    }

    async fetchFestivalResources() {
        await this.apiService.festivalService.getFestivalResources()
            .subscribe(items => {
                this.dataService.festivalRes$ = of(items);
                this.dataService.festivalRes$
                    .subscribe(res => {
                        // console.log('res: ', res);
                    });
            });
    }

    async fetchFestivalItems() {
        // 1. init variables
        await this.fetchFestivalDays();

        // 2. fetch Firestore data
        // // 2.1 Lent Profile
        await this.getCurrentLentCardSnapshot();

        this.checkLentCard();        
        // 3. Subscribe lent date to firebase. Observe for change.
        // Try to fetch from FireStore
        let docRef = doc(this.fireStore, this.collectionLent.BOUNDARIES + "/" + '2025');
        docData(docRef).subscribe({
            next: (data) => {
                // console.log("festival days:")
                // console.log(data);
                this.updateFestivalDays(data);
            },
            error: (err: any) => { },
            complete: () => { }
        });
        // this.fireStore
        //     .collection(Constants.COLLECTION_LENT_CURRENT.BOUNDARIES)
        //     .doc('2023')
        //     .snapshotChanges()
        //     .subscribe(doc => {this.updateFestivalDays(doc.payload.data())});
    }

    async fetchOfferingRecord() {
        /*
        * 1. merge snapshot entries to lent record (only filled entries)
        * 2. calculate latest total at last
        * */
        zip(
            this.apiService.festivalService.localCard$,
            this.dataService.lentRecord$,
        )
            .pipe(
                map(([card, record]) => {
                    for (let day of this.dataService.festivalDays) {
                        if (card.entries[day.getDateEN()] === undefined) {
                            continue;
                        }
                        record[day.getIndex()] = card.entries[day.getDateEN()];
                        // console.log('new record: %s', day.getDateEN(),record[day.getIndex()]);
                    }
                }),
                finalize(() => this.calculateOfferingTotal())
            )
            .subscribe(() => {});
    }

    /**
     * Fetch a lentcard snapshot from Firebase.
     * Save the snapshot from firebase to local lent card.
     */
    async getCurrentLentCardSnapshot() {
        let key = await this.dataService.getData(Constants.COLLECTION_LENT_CURRENT.CARD_KEY);
            
        if (key !== undefined && key !== null) {
            // this.lentCard$ = this.apiService.festivalService
            //     .getFirestoreCardSnapshot(key);
            // console.log("getFirestoreCardSnapshot");
            let doc = await this.apiService.festivalService.getFirestoreCardSnapshot(key);
            let snapshot = LentCard.init(doc.data());
            console.log('Lentcard firebase snapshot: ', snapshot);
            if (snapshot !== undefined) {
                // check record setting
                if (snapshot.setting !== undefined && snapshot.setting !== null && snapshot.setting.mode == 'delete') {
                    // Clear local data as instructed by firebase field.
                    await this.dataService.setData(Constants.COLLECTION_LENT_CURRENT.CARD, null).then(() => {});
                    await this.dataService.setData(Constants.COLLECTION_LENT_CURRENT.CARD_KEY, null).then(() => {});
                    await this.dataService.setData(Constants.COLLECTION_LENT_CURRENT.CARD_INFO, null).then(() => {});
                }
                // snapshot exist, if not submitted =>
                // 1. save snapshot to local card
                // 2. update offering record
                // 3. update offering total
                await this.apiService.festivalService.saveSnapshot(snapshot);
                await this.fetchOfferingRecord();
                    // .finally(() => console.log('local card value: ', this.apiService.festivalService.getLocalCardValue()));
            }
            // this.lentCard$.subscribe(snapshot => {
            // });
        } else {
            console.log("No local lentcard key!");
        }
    }

    async getCurrentLentArchive() {
        // this.dataService.lentArchive = of(LentArchive.init({
        //     device_id    : this.deviceID,
        //     user_id      : this.currentUser.username,
        //     username     : this.currentUser.fullname,
        //     summary      : this.currentAmount,
        //     last_update  : this.dateService.getCurrentTimestamp().format(Constants.TIMESTAMP_FORMAT_EN),
        //     device_model : this.getLogVersion(Constants.LOG.system.DEVICE_MODEL),
        //     os_version   : this.getLogVersion(Constants.LOG.system.OS),
        //     app_version  : this.getLogVersion(Constants.LOG.system.APP),
        // }));
        //
        // this.dataService.lentArchive
        //     .subscribe(item => {
        //         // check record setting
        //         this.dataService.getData(Constants.COLLECTION_LENT_CURRENT.SETTING)
        //             .then(value => {
        //                 if (value === undefined || value === null) {
        //                     item.record_setting = {mode: 'increasing', maximum: 0.0};
        //                 } else {
        //                     item.record_setting = value;
        //                 }
        //             });
        //
        //         // check submit status
        //         this.apiService
        //             .validateArchive({device_id: this.deviceID})
        //             .subscribe(archive => {
        //                 item.submitted = archive !== undefined;
        //             });
        //     });
    }

    /**
     * Try to open a Lent Card from local datastore.
     * Route to landing page if no card data exists.
     */
    openLentCard() {
        this.dataService.getData(Constants.COLLECTION_LENT_CURRENT.CARD_INFO)
            .then(key => {
                if (key !== undefined && key !== null) {
                    this.goToPageByURL('/lentCard/tabs', {});
                } else {
                    this.openPage('/lentLanding', '');
                }
            });
    }

    /**
     * Check lent card status.
     * 1. Check if date within LENT
     * 2. Check if card exists
     */
     checkLentCard() {
        // Check date first
        let visible : boolean;

        let lowerBound = this.dateService.formatDate(this.lentStartEnd["LENT_START_DAY"]);
        let upperBound = this.dateService.formatDate(this.lentStartEnd["LENT_END_DAY"]);
        if (this.dateService.inRange(lowerBound, upperBound)) { // Within Lent Range
            return "lentCardOverview";
        } else { // Not within Lent Range
            return 'posters';
        }
    }

}
