import {Component, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {ModalController} from '@ionic/angular';

import * as Constants from '../../../configs/constants';
import {GlobalVars} from '../../../providers/globalvars';
import {DevotionService} from '../../../providers/apiServices/devotionService';
import {Devotion} from '../../../models/devotion.model';
// import { type } from 'os';


@Component({
    selector: 'page-devotion-date',
    templateUrl: 'devotion-date.html',
    styleUrls: ['./devotion-date.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DevotionDatePage  {

    currentDate : string;
    fetchDate   : string;
    validDate   : boolean;
    devotions   : Devotion[];

    constructor(
        private router: Router,
        public globalVars: GlobalVars,
        public devotionService : DevotionService,
        public modalCtrl: ModalController,
    ) {
        this.fetchDate   = '';
        this.validDate   = true;
        this.currentDate = this.globalVars.dateService.getCurrentISOString();
    }

    ionViewWillEnter() {
        this.globalVars.dataService.getData(Constants.KEY_DEVOTION_CONTENT)
            .then(items => this.devotions = items);
    }

    ionViewWillLeave() {

    }

    changeDate(value: string | string[]) {
        if (typeof(value) == 'string')
            this.fetchDate = this.globalVars.dateService.momentStartDate(value).toISOString(true);
        else
            this.fetchDate = this.globalVars.dateService.momentStartDate(value[0]).toISOString(true);
        this.validDate = (this.fetchDate <= this.currentDate);
        if (!this.validDate) {
            this.fetchDate = null;
            // this.globalVars.presentToast('請選擇一個合理的日期。');
        }
    }

    /*
    * view devotion content by selected date
    * */
    fetchContentByDate() {
        this.globalVars.presentLoading();
        console.log('fetch date: ', this.fetchDate);
        this.devotionService.getDevotionContentByDate(this.fetchDate)
            .subscribe(item => {
                this.globalVars.customDevotion = item;
                this.globalVars.dismissLoading()
                    .then(() => this.dismissModal(true));
            });
    }

    /*
    * view devotion content by current id
    * */
    openDetail(id) {
        this.router.navigateByUrl(`devotion/${id}`)
            .then(() => this.dismissModal(true));

    }

    dismissModal(complete: boolean) {
        if (!complete) {
            this.globalVars.customDevotion = null;
        }
        this.modalCtrl.dismiss()
            .then(() => {});
    }

}
