import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';

import {IonRouterOutlet, Platform} from '@ionic/angular';
// import {SplashScreen} from '@awesome-cordova-plugins//splash-screen/ngx';
// import {StatusBar} from '@awesome-cordova-plugins//status-bar/ngx';
import { SplashScreen } from '@capacitor/splash-screen';
import {StatusBar, Style} from '@capacitor/status-bar';
import {Device} from '@awesome-cordova-plugins//device/ngx';
// import {ImageLoaderConfigService} from 'ionic-image-loader-v5';
import {filter, map, take, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

import * as Constants from './configs/constants';
import {GlobalVars} from './providers/globalvars';
import {User} from './models/user.model';
import {PushService} from './providers/pushService';
import {ThemeService} from './providers/theme.service';
import { environment } from 'src/environments/environment.prod';

interface DeeplinkMatch {
    $param: string;
}

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit,AfterViewInit {

    @ViewChild(IonRouterOutlet, {static: true}) routerOutlet: IonRouterOutlet;
    appState$: Observable<object>;

    constructor(
        private platform          : Platform,
        // private splashScreen      : SplashScreen,
        // private statusBar         : StatusBar,
        private router            : Router,
        private device            : Device,
//         private imageLoaderConfig : ImageLoaderConfigService,
        private pushService       : PushService,
        public  globalVars        : GlobalVars,
        public themeService       : ThemeService,
    ) {
        this.initializeApp();
    }

    async ngOnInit() {
        // ---------- Fetch Global Vars ---------- //
        // TODO : toggle admin filter
        // isFilterEnabled = false = admin testing
        // isFilterEnabled = true  = production
        // this.globalVars.escapeCode    = this.globalVars.isProduction;
        this.globalVars.filterEnabled = environment.filterEnabled; // this.globalVars.isProduction;
        // this.globalVars.isTesting     = this.globalVars.isProduction;
        // console.log(
        //     `configs: \n`+
        //     `isProduction: ${this.globalVars.isProduction}\n`+
        //     `isTesting: ${this.globalVars.isTesting}\n`+
        //     `filterEnabled: ${this.globalVars.filterEnabled}\n`
        // );

        this.appState$ = this.router.events
            .pipe(
                filter(e => e instanceof NavigationStart),
                map(() => {
                    const currentState = this.router.getCurrentNavigation();
                    return currentState.extras.state;
                })
            );

        this.globalVars.dataService.getData(Constants.KEY_APP_SETTINGS)
            .then(value => {
                if (value !== null) {
                    this.globalVars.bannerControl  = value['bannerControl'];
                    this.globalVars.posterControl  = value['posterControl'];
                    this.globalVars.vhControl      = value['vhControl'];
                    this.globalVars.defaultControl = value['defaultControl'];
                }
            });

        // // ---------- Fetch App Data ---------- //
        await this.globalVars.fetchData();
        console.log("After fetching global data");
        // StatusBar.setStyle({style: Style.Light});
        // StatusBar.setBackgroundColor({color: '#67278B'});
        StatusBar.setOverlaysWebView({overlay: false});
        
        // ---------- Handle App-Only Functions ---------- //
        if (this.globalVars.isApp()) {
            this.globalVars.subscribeConnection();
            this.globalVars.checkSingleLogin();
        }
    }

    ngAfterViewInit() {
        this.platform.ready().then(async () => {
            await this.initDeepLinking();
        });
    }

    initializeApp() {
        this.platform.ready().then(() => {
            // handle Notifications first.
            this.setupNotifications();

            // Config app theme
            this.themeService.configTheme();
            if (this.globalVars.isApp()) {
                // // Handle UI issues
//                this.statusBar.styleLightContent();
                SplashScreen.hide();
                // // Handle Device Info
                this.globalVars.deviceID = this.device.uuid;
            } else {
                this.globalVars.deviceID = 'WKPHC';
                this.globalVars.currentUser.username = 'WKPHC';
            }

            // Handle Storage
            let trash_keys = [
                Constants.COLLECTION_FESTIVAL,
                Constants.COLLECTION_2020_LENT,
                Constants.COLLECTION_2021_LENT,
                Constants.COLLECTION_2022_LENT.CARD_KEY,
            ];
            trash_keys.forEach(target => {
                // console.log('target: ', target);
                this.globalVars.apiService.unsubscribeListeners(String(target));
                this.globalVars.dataService.removeData(target)
                    .then(() => {});
            });

            // Handle FCM
            // if (this.globalVars.isApp()) {
            //     this.firebaseService.getToken()
            //         .then(token => {
            //             console.log(`current FCM token is: ${token}`);
            //         });
            //
            //     // ---------- Production Topics ---------- //
            //
            //     // 1. unsubscribe past topics
            //     let trash_topics = [
            //         'verses',
            //         'festivalEvent', 'festivalVerse',
            //         'testing', 'FCMTesting', 'NotificationTesting', 'AdminTesting'
            //     ];
            //     trash_topics.forEach(topic => {
            //         this.firebaseService.unsubscribeFromTopic(topic)
            //             .then(() => {});
            //     });
            //
            //     // 2. subscribe current topics
            //     let prod_topics = [
            //         'dailyVerse',
            //         'VersionUpdate',
            //         'EventReminder',
            //         'MeetingReminder',
            //         'AlertReminder',
            //         'WeatherReminder',
            //         'FestivalReminder',
            //     ];
            //     prod_topics.forEach(topic => {
            //         this.firebaseService.subscribeToTopic(topic)
            //             .then(() => {});
            //     });
            //     // ---------- Production Topics ---------- //
            //
            //     // ---------- Development Topics ---------- //
            //     // Hidden Topics:
            //     this.firebaseService.subscribeToTopic('AppNotification')
            //         .then(() => {});
            //     // ---------- Development Topics ---------- //
            //
            //     this.firebaseService.listenToNotifications()
            //         .pipe(
            //             tap(() => this.globalVars.goToPageByURL('/notifications', {}))
            //         )
            //         .subscribe(
            //             message => {
            //                 console.log(`message content : ${message}`);
            //                 console.log(`Message type: ${message.messageType}`);
            //                 if(message.messageType === 'notification'){
            //                     console.log(`Notification message received`);
            //                     if(message.tap){
            //                         console.log(`Tapped in ${message.tap}`);
            //                     }
            //                 }
            //                 console.dir(message);
            //             },
            //             error => {
            //                 console.error(error);
            //             });
            //
            //     let params = {
            //         user: this.globalVars.deviceID,
            //         last_update: this.globalVars.dateService.getCurrentTime(),
            //     };
            //     this.firebaseService.logEvent('app_open', params)
            //         .then(() => {});
            // }

            // ---------- Handle Login Status ---------- //
            this.checkLoginStatus();

            // ---------- Handle Image Loader ---------- //
//            this.imageLoaderConfig.setConcurrency(80);
            // this.imageLoaderConfig.setMaximumCacheAge(7 * 24 * 60 * 60 * 1000); // 7 days
//            this.imageLoaderConfig.setFallbackUrl('assets/icons/ic_main.png');
//            this.imageLoaderConfig.useImageTag(true);

            // ---------- Allow Refresh Data ---------- //
            this.globalVars.refreshData = true;

            this.globalVars.logApiActions();
        });

        // ---------- Handle Hardware BackButton ---------- //
        this.platform.backButton
            .subscribeWithPriority(1, async () => {
                const promptToExit = ['/home/tabs/main', '/home/tabs/festival', '/home/tabs/notification', '/home/tabs/poster', '/testingHome', '/festivalHome'];
                const disableBack = ['/card'];

                if (this.globalVars.backToExit == true) {  // Hardware back button pressed second time
                    this.globalVars.backToExit = false;
                    navigator['app'].exitApp();
                }

                if (promptToExit.includes(this.router.url)) {
//                    this.globalVars.backToExit = true;
                    this.globalVars.messageService.dismissTop()
                        .then(dismiss => {
                            if (dismiss) {
                                this.globalVars.presentConfirmationAlert(
                                    '稍後再返回永光App？',
                                    '',
                                    () => {
                                        this.globalVars.backToExit = false;
                                        navigator['app'].exitApp();
                                    }, // Positive Handler
                                    () => { this.globalVars.backToExit = true; }  // CompletedHandler
                                    );
                            }
                        });
                } else if (disableBack.includes(this.router.url)) {
                    if (!this.globalVars.canGoBack) {
                        // do nothing due to cancelled scanner
                    } else {
                        this.routerOutlet.pop()
                            .then(() => {
                                let page = this.globalVars.getPageByURL(this.router.url);
                                this.globalVars.currentPageID = (page !== undefined) ? page.id : 0;
                                this.globalVars.refreshMenu();
                            });
                    }
                } else {
                    this.routerOutlet.pop()
                        .then(() => {
                            let page = this.globalVars.getPageByURL(this.router.url);
                            this.globalVars.currentPageID = (page !== undefined) ? page.id : 0;
                            this.globalVars.refreshMenu();
                        });
                }
            });

        // ---------- Handle App Resume ---------- //
        this.platform.resume
            .subscribe(() => {
                // config app theme
                // this.themeService.retrieveTheme();
                // check app version
                this.globalVars.dataService.getData(Constants.KEY_APP_SETTINGS)
                    .then(value => {
                        if (value !== null) {
                            if (this.globalVars.isMobile()) {
                                this.globalVars.dataService.updatePending = this.globalVars.isUpdatePending(value['buildInfo']);
                                if (this.globalVars.dataService.updatePending) {
                                    this.globalVars.presentForceUpdateAlert(value.flagControl.forceUpdate)
                                        .then(() => {});
                                }
                            }
                        }
                    });
                // fetch data on resume
                if (this.globalVars.refreshData) {
                    this.globalVars.fetchData()
                        .then(() => {});
                    // resume api logs
                    this.globalVars.logApiActions();
                }
            })
    }

    private async initDeepLinking(): Promise<void> {
        if (this.platform.is('cordova')) {
            await this.initDeepLinkingBranchio();
        } else {
            await this.initDeepLinkingWeb();
        }
    }

    private async initDeepLinkingBranchio(): Promise<void> {
        try {
            const branchIo = window['Branch'];

            if (branchIo) {
                const data: DeeplinkMatch = await branchIo.initSession();
                console.log('data: ', JSON.stringify(data));
                if (data.$param !== undefined) {
                    console.log('Parameter: ', data.$param);
                }
            }
        } catch (err) {
            console.error(err);
        }
    }
    private async initDeepLinkingWeb(): Promise<void> {
        const param: string =
            this.platform.getQueryParam('$param') ||
            this.platform.getQueryParam('param') ||
            this.platform.getQueryParam('%24param');
        // console.log('Parameter: ', param);
    }

    /* Notifications
    ==================================================
    ================================================== */

    setupNotifications() {
        this.pushService.setup();
    }

    /* ==================================================================================================== */

    /* App Login */

    checkLoginStatus() {
        this.globalVars.memberService.authenticationStatus
            .subscribe(status => {
                if (status) {
                    this.globalVars.dataService.getData(Constants.KEY_CURRENT_USER)
                        .then((user: User) => {
                            if (user !== null) {
                                this.globalVars.currentUser = user;
                                if (
                                    this.globalVars.isEmpty(this.globalVars.currentUser.fullname)
                                ) {
                                    this.globalVars.presentGoAlert(
                                        '用戶尚未輸入個人資料',
                                        '立即輸入？',
                                        () => {
                                            this.router.navigate(['/profile', {lastRoute: this.router.url}]);
                                        }
                                    );
                                }
                            }
                        });
                }
            });
    }

    /* ==================================================================================================== */

    /* App Navigation */

    openPage(id: any) {
        if (id == 0) {
            this.globalVars.goToPageByID(id);
        }
        this.globalVars.pages.forEach(page => {
            if (page.id == id) {
                page.open = !page.open;
            } else {
                page.open = false;
            }
        });
    }
}
